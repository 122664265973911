import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import Countdown from 'vue3-flip-countdown'

import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fas, faTwitter)

// Kicks off the process of finding <i> tags and replacing with <svg>
dom.watch()

createApp(App)
.use(Countdown)
.component('font-awesome-icon', FontAwesomeIcon)
.mount('#app')
