<template>
  <div class="stretch">
    <div class="flex items-center justify-between ml-5 mr-5 mt-5">
      <!-- <div class="flex"> -->
        <a href="/" class="flex items-center">

          <img 
            src="../assets/img/circle.png"
            width="80"
            alt=""
            className="logo-image"
          />
          <p class="satisfy text-3xl ml-6">Arabesques</p>
        </a>
        <a class="small-button montserrat" href="https://twitter.com/ArabesquesArt" target="_blank">
          Follow on <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'twitter' }" size="xl" class="twit-icon"/> 
        </a>
      <!-- </div> -->
      <!-- <nav className="flex flex-row justify-right montserrat"> -->
        <!-- <a href="https://twitter.com/ArabesquesArt" target="_blank" class="link">
          <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'twitter' }" size="2x" class="twit-icon"/>
        </a> -->
        <!-- <font-awesome-icon icon="fa-solid fa-twitter" /> -->
        <!-- <a href="/mint" className="text-2xl m-6 text-grey">MINT</a> -->
      <!-- </nav> -->
    </div>
    <div class="landing">
      <div class="landing-image-container">
        <img v-if="!url.length" class="landing-image" src="../assets/img/ex-og2.png" alt="Arabesque base templates"/>
        <img v-else class="landing-image" :src="url" alt="Arabesque loaded"/>
      </div>
      <div class="landing-text">
        <h1 class="section-title satisfy">Ar·a·besque</h1>
        <p class="section-text montserrat">\ a-rə-besk \</p>
        <p class="section-text montserrat">noun</p>
        <p class="section-text montserrat">1) an elaborate or intricate pattern</p>
        <button class="big-button montserrat" v-on:click="getArabesque()">GENERATE</button>
        <!-- <p v-if="url.length" class="montserrat">Currently viewing Arabesque for hash:</p>
        <p v-if="url.length" class="montserrat">{{hash}}</p> -->
        <p v-if="url.length" class="section-text montserrat">The story behind Arabesques &darr;
        </p>
      </div>
    </div>
    <div class="section centered blog-padding">
      <p class="montserrat section-text"><i>“If you want to find the secrets of the universe, think in terms of energy, frequency and vibration.” Nikola Tesla.</i></p>
      <!-- <p class="montserrat section-text"><i>Nikola Tesla.</i></p> -->
    </div>
    <div class="section blog-padding">
      <h1 class="blog-title satisfy">Physics</h1>
      <p class="montserrat blog">
        Patterns emerge in sand on a metal plate vibrating at resonant frequencies. These exquisite patterns are a result of resonant modes created by standing acoustic waves on the metal plates. In other words, when the plate vibrates at just the right frequency, there are certain areas of the plate that actually "stand still", collecting the tiny bits of sand originally sprinkled onto the plate.
      </p>
      
      <div class="flex blog-padding">
        <video width="640" height="480" controls class="centered">
          <source src="https://arabesques.s3.us-east-2.amazonaws.com/IMG_5598.mp4" type="video/mp4" >
        </video>
      </div>
      
      <p class="montserrat blog">
        In the late 18th century, Ernst Chladni, known as the father of acoustics, discovered this phenomenon, by stroking a metal plate with a violin bow. These plates are now known as <i>Chladni plates</i>. This intersection of physics and art is the inspiration for <i>Arabesques</i>.
      </p>
      
      <h1 class="blog-title satisfy">Image Manipulation</h1>
      <p class="montserrat blog">
        Each plate in an Arabesque is a real metal plate, with real sand, photographed while in vibration. In some plates, the grains of sand are still visible. The captured image of the plate undergoes OpenCV transformation, using filters and color manipulation to produce a refined black square containing sand. An example of the original and transformed image are shown below.
      </p>
      
      <!-- <div class="flex items-center justify-center">
        <img class="blog-img" src="../assets/img/IMG_2473.jpg">
        <img class="blog-img" src="../assets/img/img_2471.jpg">
      </div> -->

      <div class="images">
        <div class="center-image-div">
          <img class="blog-img" src="../assets/img/IMG_2473.jpg">
          <p>Original photo</p>
        </div>
        <div class="center-image-div">
          <img class="blog-img" src="../assets/img/img_2471.jpg">
          <p>Transformed plate</p>
        </div>
      </div>

      <h1 class="blog-title satisfy">Stitching Algorithms</h1>
      
      <p class="montserrat blog">
        While these plates are beautiful themselves, there is not much that is generative about them. In order to create the final product, the plates are stitched together using either a random grid algorithm or a quadtree algorithm and then recolored to the appropriate color scheme, dictated by the first two characters in the transaction hash passed into the art generation.
      </p>
      
      <p class="montserrat blog">
        The random grid algorithm first selects <i>m</i> to dictate the mxm grid size, where 1 &#8804; m &#8804; 25. Then, as the grid is traversed, the number of grid spaces that a single Chladni plate covers is allocated via a pseudorandom generator, bounded by the mxm grid size or any existing plates already placed on the grid. Here are some examples of the random grid output:
      </p>
      
      <div class="images">
        <div class="center-image-div">
          <img class="blog-img" src="../assets/img/0xacb58353f0bb856159df3a95914040f33d0dc32e0736d409d0bdae27da412998.jpg">
        </div>
        <div class="center-image-div">
          <img class="blog-img" src="../assets/img/0x6257aa321000152af792c73f67b69380fcd9dde2d85e037e5245253288d5641a.jpg">
        </div>
      </div>
      
      <p class="montserrat blog">
        The quadtree algorithm utilizes a <a class="link" href="https://jimkang.com/quadtreevis/" target="_blank">quadtree</a> to partition the grid. Imagine a large square containing <i>n</i> points (these do not move during the subdividing process). The large square subdivides into four equally-sized, smaller squares, which each now contain a portion of the original <i>n</i> points. This process continues until each square contains at most <i>k</i> points laying within the square. The parameters, <i>n</i> and <i>k</i>, are pseudorandomly assigned.
      </p>
      
      <p class="montserrat blog">
        The distribution of the <i>n</i> points in the grid allows for interesting patterns to occur. The algorithm selects from 6 different types of distributions: uniform, Gaussian, inverse Gaussian, triangle, inverse triangle, and a custom-crafted distribution of superimposed distributions that I can't really put into words. Here are some examples:
      </p>
      
      <div class="images">
        <div class="center-image-div">
          <img class="blog-img" src="../assets/img/0xb6767d35d344d90cc16c8ddc359b40b8e4924755b1587bc02c1a0b5743f24570.jpg">
          <p>Custom distribution</p>
        </div>
        <div class="center-image-div">
          <img class="blog-img" src="../assets/img/0x25fde1767233af212f37464f9c5b7017cc0f5a5556cb8fd9c613bdfd4f3e933b.jpg">
          <p>Inverse triangle distribution</p>
        </div>
      </div>
      <div class="images">
        <div class="center-image-div">
          <img class="blog-img" src="../assets/img/0x1f01ad7e93a45ed718af63a6c3f69529b19bb289a7c6fab005196461c99e04c6.jpg">
          <p>Uniform distribution</p>
        </div>
        <div class="center-image-div">
          <img class="blog-img" src="../assets/img/0xc66d9dd5c47f187b0ae7c582e012a827256956c7e1271cc51106021d20088ce6.jpg">
          <p>Gaussian distribution</p>
        </div>
      </div>

      <h1 class="blog-title satisfy">Color Schemes</h1>
      
      <p class="montserrat blog">
        After quite a bit of soul-searching, I found some colorings that I thought would be interesting combinations. All color schemes are shown below:
      </p>

      <div class="images">
        <div class="center-image-div-mini">
          <img class="blog-img" src="../assets/img/original.jpg">
          <p>Original</p>
        </div>
        <div class="center-image-div-mini">
          <img class="blog-img" src="../assets/img/dark.jpg">
          <p>Dark</p>
        </div>
        <div class="center-image-div-mini">
          <img class="blog-img" src="../assets/img/snow.jpg">
          <p>Snow</p>
        </div>
      </div>
      <div class="images">
        <div class="center-image-div-mini">
          <img class="blog-img" src="../assets/img/sundaymorning.jpg">
          <p>Sunday Morning</p>
        </div>
        <div class="center-image-div-mini">
          <img class="blog-img" src="../assets/img/harleyquinn.jpg">
          <p>Harley Q</p>
        </div>
        <div class="center-image-div-mini">
          <img class="blog-img" src="../assets/img/ocean.jpg">
          <p>Ocean</p>
        </div>
      </div>

      <div class="images">
        <div class="center-image-div-mini">
          <img class="blog-img" src="../assets/img/pistachio.jpg">
          <p>Pistachio</p>
        </div>
        <div class="center-image-div-mini">
          <img class="blog-img" src="../assets/img/crayons.jpg">
          <p>Crayons</p>
        </div>
        <div class="center-image-div-mini">
          <img class="blog-img" src="../assets/img/desertoasis.jpg">
          <p>Desert Oasis</p>
        </div>
      </div>
      <div class="images">
        <div class="center-image-div-mini">
          <img class="blog-img" src="../assets/img/discotheque.jpg">
          <p>Discotheque</p>
        </div>
        <div class="center-image-div-mini">
          <img class="blog-img" src="../assets/img/violettrees.jpg">
          <p>Violet Trees</p>
        </div>
        <div class="center-image-div-mini">
          <img class="blog-img" src="../assets/img/elsa.jpg">
          <p>Elsa</p>
        </div>
      </div>
      <div class="images">
        <div class="center-image-div-mini">
          <img class="blog-img" src="../assets/img/nautical.jpg">
          <p>Yacht Week</p>
        </div>
        <div class="center-image-div-mini">
          <img class="blog-img" src="../assets/img/neon.jpg">
          <p>Neon</p>
        </div>
        <div class="center-image-div-mini">
          <img class="blog-img" src="../assets/img/flamingo.jpg">
          <p>Flamingo</p>
        </div>
      </div>

      <div class="images">
        <div class="center-image-div-mini">
          <img class="blog-img" src="../assets/img/forestlights.jpg">
          <p>Forest Lights</p>
        </div>
        <div class="center-image-div-mini">
          <img class="blog-img" src="../assets/img/inferno.jpg">
          <p>Inferno</p>
        </div>
        <div class="center-image-div-mini">
          <img class="blog-img" src="../assets/img/whalewatching.jpg">
          <p>Whalewatching</p>
        </div>
      </div>
      <div class="images">
        <div class="center-image-div-mini">
          <img class="blog-img" src="../assets/img/choclate.jpg">
          <p>Chocolate</p>
        </div>
        <div class="center-image-div-mini">
          <img class="blog-img" src="../assets/img/grape.jpg">
          <p>Grape</p>
        </div>
        <div class="center-image-div-mini">
          <img class="blog-img" src="../assets/img/navy.jpg">
          <p>Navy</p>
        </div>
      </div>

      <h1 class="blog-title satisfy">In Conclusion</h1>
      <p class="montserrat blog">
        Thanks for taking the time to read through this post. Follow this project on <a class="link" href="https://twitter.com/ArabesquesArt" target="_blank">Twitter</a>!
        Arabesques is a reflection of myself, merging my passions in physics, programming, and art. Hope you enjoy this project as much as I do. Feel free to check out my <a class="link" href="https://kylieying.com" target="_blank">personal website</a> to learn more about me!
      </p>
      
    </div>
    <div class="section flex justify-center items-center">
      <div class="fold-section">
        <img src="../assets/img/this.png" alt="'THIS'" class="catchphrase"/>
        <img src="../assets/img/is.png" alt="'IS'" class="catchphrase"/>
      </div>
      <div class="fold-section">
        <img src="../assets/img/seeing.png" alt="'SEEING'" class="catchphrase"/>
        <img src="../assets/img/sound.png" alt="'SOUND.'" class="catchphrase"/>
      </div>
    </div>
    <div class="centered">
      <p class="montserrat blog">Inspired by dank memes and big dreams :')</p>
      <p class="montserrat blog">Created by <a class="link" href="https://kylieying.com" target="_blank">Kylie Ying</a></p>
      <p class="montserrat blog">Follow on <a class="link" href="https://twitter.com/ArabesquesArt" target="_blank">Twitter</a></p>
    </div>
  </div>

</template>

<script>
import axios from "axios";
// import TwitterButton from "vue-share-buttons/src/components/TwitterButton";
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: "HomePage",
  data() {
    return {
      url: "",
      hash: ""
    };
  },
  // components: { FontAwesomeIcon },
  methods: {
    async getArabesque() {
      try {
        const res = await axios.get(`https://abqs-v0.herokuapp.com/arabesque/`);
        // const res = await axios.get(`http://localhost:8000/arabesque/`);
        // console.log(res);
        this.url = res.data.url;
        this.hash = res.data.hash;
      } catch (error) {
        console.log(error);
      }
    }
  },
};
</script>

<style>
@import '../index.css';
</style>
